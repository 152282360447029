import React, { FC } from 'react';

import { BaseComponentProps, ProfileLayout } from '../../../../types';
import DesktopResponsiveWidget from './DesktopResponsiveWidget';
import DesktopNonResponsiveWidget from './DesktopNonResponsiveWidget';

const getWidgetProps = (props: BaseComponentProps) => {
  const hostFromProps = props.host;
  const { profileLayout, isWidgetPlugin } = props.computed;
  const isFullWidth = profileLayout === ProfileLayout.FullWidth;
  const dimensions = isFullWidth
    ? { width: 980, height: 250 }
    : { width: 250, height: 256 };

  const host = isWidgetPlugin
    ? { ...hostFromProps, dimensions }
    : hostFromProps;

  return { ...props, host };
};

const DesktopWidget: FC<BaseComponentProps> = (props) => {
  const widgetsProps = getWidgetProps(props);

  return props.computed.profileLayout === ProfileLayout.FullWidth ? (
    <DesktopResponsiveWidget {...widgetsProps} />
  ) : (
    <DesktopNonResponsiveWidget {...widgetsProps} />
  );
};

export default DesktopWidget;
